<template>
  <div>
    <v-row class="mb-5">
      <v-col>
        <v-btn
          :color="tab === 'warehouse' ? 'primary' : 'default'"
          block
          @click="changeTab('warehouse')"
          >Live - {{ $t("labels.warehouse") }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          :color="tab === 'warehouse-history' ? 'primary' : 'default'"
          block
          @click="changeTab('warehouse-history')"
          >History - {{ $t("labels.warehouse") }}
        </v-btn>
      </v-col>
    </v-row>

    <div>
      <DashboardLive
        v-if="['warehouse'].includes(tab)"
        :tab="tab"
        :key="`tab_${tab}`"
      />
      <DashboardHistory
        v-if="['warehouse-history'].includes(tab)"
        :tab="tab"
        :key="`tab_${tab}`"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DashboardLive: () => import("@/components/dashboard/DashboardLive"),
    DashboardHistory: () => import("@/components/dashboard/DashboardHistory"),
  },
  name: "Dashboard",
  data: () => ({
    tab: "warehouse",
  }),
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
  },
};
</script>

<style scoped></style>
